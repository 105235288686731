@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
*{
    font-family: 'Poppins', sans-serif;
}
.read-or-hide{
    color: #1D1D1B;
    cursor: pointer;
    font-weight: 600;
  }

  .body, html{
      scroll-behavior: smooth;
  }
  body {
    overflow-x: hidden;
  }
  

.Example input, .Example button {
    font: inherit;
}
.Example header {
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    padding: 20px;
    color: white;
}
.Example header h1 {
    font-size: inherit;
    margin: 0;
}
.Example__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
}
.Example__container__load {
    margin-top: 1em;
    color: white;
}
.Example__container__document {
    overflow-x: hidden;
    margin: 1em 0;
    margin-top: 100px !important;
}
.Example__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Example__container__document .react-pdf__Page {
    max-width: calc(100% - 2em);
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    margin: 1em;
}
.Example__container__document .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
}
.Example__container__document .react-pdf__message {
    padding: 20px;
    color: white;
}

.menu {
    position: absolute;
    background-color: #009FE3;
    color: #fff;
    z-index: 1;
  }
  
  .menu--right {
    top: 0;
    right: 0;
    width: 289px;
    height: 463px;
  }
 
