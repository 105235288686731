.menu {
    position: absolute;
    background-color: #009FE3;
    color: #fff;
    z-index: 1;
  }
  
  .menu--right {
    top: 0;
    right: 0;
    width: 289px;
    height: 463px;
  }
 